import React from "react";
import { graphql, Link as GatsbyLink } from "gatsby";
import styled from "styled-components";
// Components
import { Box, Button, Center, Container, Link, Flex, Heading, Icon, Box as Section, Text } from "@chakra-ui/react";
import { SEO } from "../components/SEO";
import Img from "gatsby-image";
import { FaHandPointRight } from "react-icons/fa";
import { ArrowRightIcon } from "@chakra-ui/icons";

const WorkPage = ({ data }) => {
  return (
    <>
      <SEO title="Work" />

      <StyledSection backgroundColor="#232F3E" marginTop="50px">
        <Card className="clicked">
          <CardFront className="front">
            <LogoContainer>
              <Img fluid={data.aws.childImageSharp.fluid} />
            </LogoContainer>
          </CardFront>
          <CardBack className="back">
            <CardGrid className="card__grid">
              <CardColumn>
                <CardImage>
                  <Img fluid={data.awsCloud.childImageSharp.fluid} />
                </CardImage>
              </CardColumn>
              <CardColumn>
                <h4 className="card__title">Cloud Computing Services</h4>
                <p className="card__content">Develops global marketing campaigns for Amazon Web Services</p>
              </CardColumn>
            </CardGrid>
          </CardBack>
        </Card>
      </StyledSection>

      <StyledSection backgroundColor="#00234c">
        <Card className="clicked">
          <CardFront className="front">
            <LogoContainer>
              <Img fluid={data.biotronik.childImageSharp.fluid} />
            </LogoContainer>
          </CardFront>
          <CardBack className="back">
            <CardGrid className="card__grid">
              <CardColumn>
                <CardImage>
                  <Img fluid={data.biotronikPacemaker.childImageSharp.fluid} />
                </CardImage>
              </CardColumn>
              <CardColumn>
                <h4 className="card__title">Cardiac Rhythm Management</h4>
                <p className="card__content">
                  Led all digital marketing for the cardiac rhythm product portfolio and patient education campaigns
                </p>
              </CardColumn>
            </CardGrid>
          </CardBack>
        </Card>
      </StyledSection>

      <StyledSection backgroundColor="#c9252c">
        <Card className="clicked">
          <CardFront className="front">
            <LogoContainer>
              <Img fluid={data.merit.childImageSharp.fluid} />
            </LogoContainer>
          </CardFront>
          <CardBack className="back">
            <CardGrid className="card__grid">
              <CardColumn>
                <CardImage>
                  <Img fluid={data.meritEmbo.childImageSharp.fluid} />
                </CardImage>
              </CardColumn>
              <CardColumn>
                <h4 className="card__title">Emblotherapy</h4>
                <p className="card__content">Managed the emblotherapy product portfolio and patient education campaigns</p>
              </CardColumn>
            </CardGrid>
          </CardBack>
        </Card>
      </StyledSection>

      <StyledSection backgroundColor="white">
        <Card className="clicked">
          <CardFront className="front">
            <LogoContainer>
              <Img fluid={data.stryker.childImageSharp.fluid} />
            </LogoContainer>
          </CardFront>
          <CardBack className="back">
            <CardGrid className="card__grid">
              <CardColumn>
                <CardImage>
                  <Img fluid={data.strykerArthro.childImageSharp.fluid} />
                </CardImage>
              </CardColumn>
              <CardColumn>
                <h4 className="card__title" style={{ color: "var(--color-primary)", textShadow: "none" }}>
                  Arthoscopy & Visualization
                </h4>
                <p className="card__content" style={{ color: "var(--color-gray-700)" }}>
                  Managed the arthroscopy and visualization portfolios for international markets
                </p>
              </CardColumn>
            </CardGrid>
          </CardBack>
        </Card>
      </StyledSection>

      <Section background="brand.lightShades">
        <Center minHeight="4rem" p={4} py={20}>
          <Box>
            <Text textAlign="center" color="brand.darkAccent" fontSize={["lg", "xl"]} fontWeight="medium">
              Enough about me,
            </Text>
            <Text mt={0} textAlign="center" color="brand.darkAccent" fontSize={["md", "lg"]} fontWeight="thin">
              let's hear a little more{" "}
              <Text as="i" fontWeight="bold">
                from
              </Text>{" "}
              me...
            </Text>
            <Box mt={4} textAlign="center">
              <GatsbyLink to="/blog">
                <Link color="brand.main">
                  <Flex gridGap={2} alignItems="center" justifyContent="center">
                    <Text display="inline-block" pt="1px" fontSize="xl" fontWeight="bold" textTransform="uppercase">
                      Check out my blog
                    </Text>
                    <ArrowRightIcon w={3} h={3} />
                  </Flex>
                </Link>
              </GatsbyLink>
            </Box>
          </Box>
        </Center>
      </Section>
    </>
  );
};

const StyledSection = styled(Section)`
  min-height: 50vh;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  font-weight: var(--font-weight-bold);

  &:nth-child(even) .card__grid {
    flex-direction: row-reverse;
  }
`;

const Card = styled(Container)`
  color: #7994a8;
  width: 200px;
  min-height: 50vh;
  cursor: pointer;
  perspective: 1000px;
  position: relative;

  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);

  .back {
    visibility: hidden;
    transition: visibility 0.5s, opacity 0.5s linear;
  }

  &:hover .back {
    visibility: visible;
  }

  &:hover .front {
    transform: rotateY(180deg);
  }
  &:hover .back .card__grid {
    transform-origin: center;
    transform: rotateY(0deg);
  }

  @media ${({ theme }) => theme.mq.sm} {
    min-height: initial;
  }
`;
const CardFront = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 6px;
  text-align: center;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  backface-visibility: hidden;
  transform-style: preserve-3d;
  transition: all ease-in-out 600ms;

  font-size: 1.618rem;
  font-weight: 600;
  color: var(--color-gray-400);
`;
const LogoContainer = styled.div`
  width: 250px;
`;
const CardBack = styled.div`
  border-radius: 6px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  width: 100%;
  min-width: 360px;
  overflow: hidden;
  padding: 0 3rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: var(--color-gray-400);
  font-size: var(--font-size-lg);

  @media ${({ theme }) => theme.mq.sm} {
    min-width: var(--screen-md);
    font-size: var(--font-size-xl);
  }
`;
const CardGrid = styled.div`
  display: block;
  gap: 2rem;
  grid-template-columns: 1fr 2fr;
  -webkit-visibility: hidden;
  backface-visibility: hidden;
  transform-style: preserve-3d;
  transition: all ease-in-out 600ms;
  transform-origin: center;
  transform: rotateY(180deg);

  .card__title {
    font-size: 1.2rem;
    color: var(--color-primary-light);
    text-shadow: 1px 1px 2px var(--color-gray-800);
    @media ${({ theme }) => theme.mq.sm} {
      font-size: 1.6rem;
    }
  }
  .card__content {
    font-size: 1rem;
    font-weight: var(--font-weight-medium);
    color: var(--color-gray-200);

    @media ${({ theme }) => theme.mq.sm} {
      font-size: 1.2rem;
    }
  }

  @media ${({ theme }) => theme.mq.sm} {
    display: flex;
  }
`;
const CardColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
`;

const CardImage = styled.div`
  width: 70%;
  align-self: center;
  @media ${({ theme }) => theme.mq.sm} {
    width: 250px;
  }
`;
export const pageQuery = graphql`
  query {
    desert: file(relativePath: { eq: "desert.jpg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    overpass: file(relativePath: { eq: "ocean-gate.jpg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    stryker: file(relativePath: { eq: "stryker-logo.png" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 500) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    strykerArthro: file(relativePath: { eq: "stryker-arthroscopy.jpg" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 500) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    biotronik: file(relativePath: { eq: "biotronik-logo.png" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 500) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    biotronikPacemaker: file(relativePath: { eq: "biotronik-pacemaker.png" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 500) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    merit: file(relativePath: { eq: "merit-logo.png" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 500) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    meritEmbo: file(relativePath: { eq: "merit-embolization.png" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 500) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    aws: file(relativePath: { eq: "aws-logo.png" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 500) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    awsCloud: file(relativePath: { eq: "aws-cloud.png" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 500) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;
export default WorkPage;
